<template>
  <div class="footer">
    <div class="footer-column-container">
      <div class="link-container">
        <h3>{{ $t("products-title") }}</h3>
        <router-link to="/calipers">{{ $t("calipers-title") }}</router-link>
        <router-link to="/a370">{{ $t("a370-title") }}</router-link>
        <router-link to="/a400">{{ $t("a400-title") }}</router-link>
        <router-link to="/a410">{{ $t("a410-title") }}</router-link>
        <router-link to="/a420">{{ $t("a420-title") }}</router-link>
      </div>
      <div class="link-container">
        <h3 class="hide">-</h3>
        <router-link to="/rotors">{{ $t("rotors-title") }}</router-link>
        <router-link to="/pads">{{ $t("pads-title") }}</router-link>
        <router-link to="/cooling">{{ $t("cooling-title") }}</router-link>
        <router-link to="/epb">{{ $t("epb-title") }}</router-link>
      </div>
      <div class="link-container">
        <h3>Upgrades</h3>
        <router-link to="/oes">{{ $t("oes-title") }}</router-link>
        <router-link to="/upgrade-kit">{{
          $t("upgrade-kit-title")
        }}</router-link>
      </div>
      <div class="link-container">
        <h3>Other</h3>
        <router-link to="/history">{{ $t("history-title") }}</router-link>
        <router-link to="/projects">{{ $t("projects-title") }}</router-link>
        <router-link to="/official-dealers">{{
          $t("dealers-title")
        }}</router-link>
        <router-link to="/contact-us">{{ $t("contact-us-title") }}</router-link>
      </div>
      <div class="link-container">
        <h3>Certifications</h3>
        <div class="row">
          <a href="https://www.tuv.at" target="_blank" rel="noreferrer">
            <img :src="certificates[0]" alt="TÜV Austria" />
          </a>
          <a href="https://www.vdat.org" target="_blank" rel="noreferrer">
            <img :src="certificates[1]" alt="VDAT" />
          </a>
        </div>
      </div>
    </div>
    <div id="bottom">
      <div id="copyright">
        Copyright © 2020 CARBO™ BRAKE by SICOM Automotive GmbH | c/o
        Klassikstadt Frankfurt - Orber Str. 4A - 60386 - Frankfurt am Main |
        USt-IdNr. DE305972759 |
        <router-link to="/privacy-and-cookie-policy">{{
          $t("privacy-title")
        }}</router-link>
        | Created by
        <a href="https://www.takorabo.com" target="_blank">Takorabo</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {},
  data() {
    return {
      certificates: [
          require("../assets/tuv_austria.svg"),
          require("../assets/vdat.png")
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
